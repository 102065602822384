import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import App from './App';
import * as serviceWorker from './serviceWorker';

//import './App.css';
import './assets/scss/style.scss';

import { Auth0Provider } from "@auth0/auth0-react";

const history = createBrowserHistory();

ReactDOM.render(
  <Auth0Provider
    domain="dev-n89tncsc.us.auth0.com"
    clientId="TOeAK1o2j9mkLTg5KSwShhLPpRvDuygK"
    redirectUri={window.location.origin}
  >
  <Router history={history}>
    <App />
  </Router>
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
