import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import GenericSection from '../components/sections/GenericSection';
import Header from '../components/layout/Header';
const ConnectPage = () => {
    



  return (
    <>
    <Header navPosition="right" className="reveal-from-bottom" />
    <main className="site-content">
    </main>
      <FeaturesTiles className="pt-60 mb-32 reveal-from-bottom" data-reveal-delay="500" />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Testimonial topDivider />
      <Cta split />
    </>
  );
}

export default ConnectPage ;